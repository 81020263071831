<template>
  <v-container class="px-8 sw-pt-10" fluid>
    <transition name="fade">
      <div v-if="isDataLoaded">
        <v-card flat max-width="800" class="mx-auto">
          <v-row class="no-gutters">
            <v-col cols="12">
              <div
                class="sw-page-title"
                :style="getTitleConfig"
                v-if="model && model.article_title_visibility"
              >
                {{ model.name }}
              </div>
              <div v-html="model.body"></div>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </transition>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    isDataLoaded: false,
    model: {
      id: null,
      name: "",
      body: "",
      group_plugin_id: null,
      categories: [],
      publish_at: null,
      publish_until: null,
      article_title_alignment: "left",
      article_title_visibility: true,
    },
  }),
  computed: {
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    articleId() {
      return this.$route?.params?.article_id;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
    getTitleConfig() {
      return {
        "text-align": this.model.article_title_alignment || "left",
      };
    },
  },
  methods: {
    articleTransformer(item) {
      return {
        id: item.id,
        name: item.name,
        body: item.body,
        group_plugin_id: item.group_plugin_id,
        categories: item.categories,
        publish_at: item.publish_at,
        publish_until: item.publish_until,
        article_title_alignment:
          item.attributes.article_title_alignment || "left",
        article_title_visibility:
          item.attributes.article_title_visibility == null
            ? true
            : Boolean(item.attributes.article_title_visibility),
      };
    },
    getGroupArticle() {
      if (!this.groupId || !this.articleId) {
        return;
      }

      this.isDataLoaded = false;

      this.$http
        .get(`/groups/${this.groupId}/articles/${this.articleId}`, {
          params: {
            lang: this.appLanguage,
            with_attributes: 1,
          },
        })
        .then((response) => {
          this.isDataLoaded = true;

          if (!response?.data?.data) return;
          this.model = this.articleTransformer(response.data.data);
        })
        .catch(() => {
          this.isDataLoaded = true;
        });
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getGroupArticle();
      },
    },
    appLanguage: {
      handler() {
        this.getGroupArticle();
      },
    },
  },
};
</script>

<style lang="scss"></style>
